
import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { mudarSocket, mudarMensagens } from '../actions/AppActions';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import { URL_SOCKET } from '../variables';

// users

class ConexaoProcesso extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            mensagens_notificacao: {}
        };
        this.list_peers = {};
        this.configuration = {
            iceServers: [
                { urls: "stun:stun.1.google.com:19302" },
                {
                    'urls': 'turn:digivest.enterscience.com.br:3478?transport=udp',
                    'credential': 'coconuts',
                    'username': 'matheus'
                },
                {
                    'urls': 'turn:turn6.enterscience.com.br:3478?transport=udp',
                    'credential': 'coconuts',
                    'username': 'matheus'
                },
                {
                    'urls': 'turn:turn7.enterscience.com.br:3478?transport=udp',
                    'credential': 'coconuts',
                    'username': 'matheus'
                },
                {
                    'urls': 'turn:turn4.enterscience.com.br:3478?transport=udp',
                    'credential': 'coconuts',
                    'username': 'matheus'
                }

            ],
            sdpSemantics: 'unified-plan'
        };
    }

    


  
    

    componentDidMount() {
        this.video_init();
    }

    

    componentWillUnmount() {
        this.socket.close();
        this.props.mudarSocket(null);
    }


    render() {
        return (
            <div>
               
            </div>
        );
    }
}
const mapsStateToProps = (state) => (
    {
        faculdade: state.AppReducer.faculdade,
        sala: state.AppReducer.sala,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
        comunicado_geral: state.AppReducer.comunicado_geral,
        stream: state.AppReducer.stream,
        mensagens: state.AppReducer.mensagens,
    }
);

export default connect(mapsStateToProps, { mudarSocket, mudarMensagens })(ConexaoProcesso);


